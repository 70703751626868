<template>
  <div class="container">
    <div id ='loader' v-if="is_loading"></div>
    <div v-else>
    <div class="page-progress-wrap">
      <h3>{{ !_.isEmpty(dataDetails) ? dataDetails.progress_bar.title : '' }}</h3>
      <div class="progress-box">
        <img :src="!_.isEmpty(dataDetails) ? dataDetails.progress_bar.progress_box[0].image_link: ''" alt="">
        <span>Input</span>
      </div>
      <div class="progress-box">
        <img :src="!_.isEmpty(dataDetails) ? dataDetails.progress_bar.progress_box[1].image_link: ''" alt="">
        <span>Confirm</span>
      </div>
      <div class="done-box">
        <img :src="!_.isEmpty(dataDetails) ? dataDetails.progress_bar.progress_box[2].image_link: ''" alt="">
        <span>Done</span>
      </div>
    </div>
    <div class="confirm-wrap">
      <img :src="!_.isEmpty(dataDetails) ? dataDetails.image_link: ''" alt="">
      <p v-html="!_.isEmpty(dataDetails) ? dataDetails.confirmation_text: '' "><br><br></p>
      <h4>{{ !_.isEmpty(dataDetails) ? dataDetails.add_to_favourite: '' }}</h4>
      <div class="btn-wrapper justify-between" v-if="this.$route.params.reason === 'topup-success'" >
        <a class="btn no" @click.prevent="againTopup" href="javascript:void(0);">{{ !_.isEmpty(dataDetails) ? dataDetails.no_text: '' }}</a>
        <a class="btn yes" @click="goToFavouritePage()" href="javascript:void(0);" data-topup_country="bn">{{ !_.isEmpty(dataDetails) ? dataDetails.yes_text: '' }}</a>
      </div>
      <a class="btn topup-again" @click.prevent="againTopup" href="javascript:void(0);">{{ !_.isEmpty(dataDetails) ? dataDetails.btn_text: '' }}</a>
      <br>
      <button class="btn resend" @click="retryAgain" v-if="resendBtnShow" id="resend" href="javascript:void(0)" :disabled="isTimerRunning" data-service_page="talktime_topup" data-service_id="11" data-option_id="260" data-service_name="talktime_topup">{{ !_.isEmpty(dataDetails) ? dataDetails.resend_btn_text: '' }}</button>
      <div class="otp-timer" v-if="resendBtnShow"><span id="timer">{{formattedTimerCount}}</span></div>
    </div>
    <br>
    <div style="text-align: center;">
      <a :href="!_.isEmpty(dataDetails) ? dataDetails.refer_card.share_url: ''" id="topup_refer"
         :data-url="!_.isEmpty(dataDetails) ? dataDetails.refer_card.share_url: ''" data-message="সহজ টপ-আপ"
         data-product="bd_topup" data-option_id="238" data-service_id="11">
        <img :src="!_.isEmpty(dataDetails) ? dataDetails.refer_card.image_url: ''" alt="bd_topup">
      </a>
    </div>
    <br>
    <div class="overlay" v-if="is_loading">
      <i class="fas fa-2x fa-sync-alt fa-spin"></i>
    </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { saveUserJourney } from './../../../services/api/saveUserJourney'
import { updateEvent } from '@/services/api/vivr-engine-helper'
import { gtmDataPush } from '../../../../public/front/js/gtmHepler'
export default {
  name: 'topupSuccessFailed',
  data () {
    return {
      apiUrl: 'content/',
      dataDetails: {},
      pictureBaseUrl: process.env.VUE_APP_MAIN_URL,
      service: sessionStorage.getItem('service') ?? '',
      packagePrice: sessionStorage.getItem('packagePrice') ?? '',
      packageAmountBDT: sessionStorage.getItem('packageAmount'),
      msisdn: localStorage.getItem('msisdn') ?? '',
      languageId: sessionStorage.getItem('language') ?? '1',
      walletId: sessionStorage.getItem('walletId') ?? '',
      totalPoint: sessionStorage.getItem('totalPoint') ?? '',
      receiverMsisdn: sessionStorage.getItem('receiverMsisdn'),
      receiverCountry: sessionStorage.getItem('receiverCountry'),
      receiverOperatorId: sessionStorage.getItem('receiverOperatorId'),
      datapackId: sessionStorage.getItem('datapackId'),
      requestFor: '',
      reason: '',
      is_loading: true,
      resendBtnShow: false,
      packageName: sessionStorage.getItem('packageName') ?? '',
      sessionId: sessionStorage.getItem('sessionId'),
      operatorId: localStorage.getItem('operatorId'),
      sendRechargeUrl: {},
      timerCount: 59
    }
  },
  mounted () {
    this.init()
    gtmDataPush('null', this.msisdn, this.receiverNumber, this.packageName ?? 'talk-time', this.packagePrice)
  },
  computed: {
    isTimerRunning () {
      return this.timerCount > 0
    },
    formattedTimerCount () {
      return this.timerCount.toString().padStart(5, '00:0')
    }
  },
  watch: {
    timerCount: {
      handler (value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--
          }, 1000)
        }
      },
      immediate: true // This ensures the watcher is triggered upon creation
    }
  },
  methods: {
    async init () {
      if (this.service === 'internet_topup') {
        this.requestFor = 'internet'
        this.sendRechargeUrl = 'send-topup-internet'
      } else {
        this.requestFor = 'topup'
        this.sendRechargeUrl = 'send-talktime'
      }
      if (this.$route.params.reason === 'topup-success') {
        this.resendBtnShow = true
        this.apiUrl += 'mo-topup-success' // age top-up success call kora hoichilo
        this.reason = 'topup-success'
        await this.sendRechargeToUser()
      } else {
        this.apiUrl += 'topup-failed'
        this.reason = 'topup-failed'
      }
      await this.getData().then(response => {
        this.dataDetails = response.data
        this.is_loading = false
      })
      const pageID = this.dataDetails.prompt.pageID
      const eventID = this.dataDetails.prompt.eventID
      updateEvent(pageID, eventID)
      // pore live checking er somoy on korte hobe //
      saveUserJourney(this.operatorId, 'top_up_success_failed', this.msisdn, this.languageId, this.sessionId)
    },
    getData () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl, {
            languageId: this.languageId,
            requestFor: this.requestFor,
            msisdn: this.msisdn
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    retryAgain () {
      this.timerCount = 59
      this.sendRechargeToUser()
    },
    sendRechargeToUser () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.sendRechargeUrl, {
            operatorId: this.operatorId,
            languageId: this.languageId,
            requestFor: this.requestFor,
            msisdn: this.msisdn,
            receiverMsisdn: this.receiverMsisdn,
            packageAmountBDT: this.packageAmountBDT,
            packagePrice: this.packagePrice,
            receiverCountry: this.receiverCountry,
            receiverOperatorId: this.receiverOperatorId,
            walletId: this.walletId,
            totalPoint: this.totalPoint,
            datapackId: this.datapackId
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    goToFavouritePage () {
      this.$router.push({ name: 'favourite-number-add', params: { receiverMsisdn: this.receiverMsisdn, receiverCountry: this.receiverCountry } })
    },
    againTopup () {
      this.$router.push({ name: 'topUp', params: { service: 'topup' } })
    }
  }
}
</script>

<style scoped>

</style>
